/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '../node_modules/common-module/common-module.less';

@import './assets/styles/index';

@import './assets/fonts/Roboto/roboto';

html * {
  font-family: 'Roboto', sans-serif;
}
