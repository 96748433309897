.ant-table {
  tr:nth-child(even) td {
    background-color: @c-grey6;
  }

  .ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  tfoot td {
    background-color: @c-grey6;
    font-weight: 700;
  }
}
