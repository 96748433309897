.loader-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: block;
  width: 200px;
  height: 200px;
  margin: 20px auto;
  background-image: url("/app/src/assets/images/default-logo.svg");
  background-size: 100px 100px;
  background-position: center center;
  background-repeat: no-repeat;
}

.loader-overlay {
  position: absolute;
  border: 26px solid #f3f3f3;
  //TODO: maybe this is primary color ?
  border-top: 26px solid #47CCB5;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: loader-spin 2s linear infinite;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
