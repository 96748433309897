.layout-logout {
  display: flex;
  align-items: center;
  padding: @padding-2;
  cursor: pointer;

  &__user {
    flex: 1;

    .ant-avatar {
      margin-right: @margin-1;
    }
  }

  &__sign-out {
    margin-left: @margin-1;
  }

  &--collapsed {
    justify-content: center;

    .layout-logout__sign-out {
      margin-left: 0;
    }
  }
}
