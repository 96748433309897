.crm-table {
  &-actions {
    .crm-dropdown-button {
      button {
        border: none;
        box-shadow: none;
      }
    }
  }
}
