@base-font-size: 16;

.rem(@selector, @pixels) {
  @remValue: round((@pixels / @base-font-size), 3);
    @{selector}: ~"@{remValue}rem";
}


.font(@font-size, @font-weight: false, @line-height: 140%) {
  .rem(font-size, @font-size);
  line-height: @line-height;
  & when not (@font-weight = false) {
    font-weight: @font-weight;
  }
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}
